import * as React from "react"
import { useState, useEffect, useContext } from "react"

import Layout from "../../components/common/Layout"
import { MetaData } from "../../components/common/meta"
import kartingImage from "../../images/go-karting-driver.jpg"
import { PeopleFill } from "@styled-icons/bootstrap/PeopleFill"
import { CalendarFill } from "@styled-icons/bootstrap/CalendarFill"
import { Location } from "@styled-icons/icomoon/Location"
import { ShieldFillCheck } from "@styled-icons/bootstrap/ShieldFillCheck"
import { Timer } from '@styled-icons/boxicons-regular/Timer'
import { ClientOnly } from "../../components/common"
import FirebaseProvider from "../../components/idealline/Firebase"
import ProfileThumb from "../../components/idealline/ProfileThumb"
import { FirebaseContext } from "../../components/idealline/Firebase"
import "firebase/auth"

function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString("en-US", options)
}

const PortimaoRace = ({ location, data }) => {
    const RACE_ID = location.pathname
        .replace("[...]", "")
        .replace("/races/", "")
        .replace(/\/$/, "")
    const FIRST_NAME = "First_Name"
    const LAST_NAME = "Last_Name"
    const EMAIL = "Email"
    const FORM_URL = `https://creatorapp.zohopublic.eu/karting/karting-check-in/form-embed/Registration_Form/yaDNHuwkJAnDzOFVVSyap8sCZ66HRtqPf9yJuz1GTaSPu6mEfKXNu73PaasPDCUj4fPwOvSwwV5hZ6zH7e3eTaPeTJGYbUPW5gwQ?Race_to_participate=${RACE_ID}&${FIRST_NAME}=&${LAST_NAME}=&mobile=&${EMAIL}=`

    const [availableSlots, setAvailableSlots] = useState(null)
    const [date, setDate] = useState(null)
    const [formUrl, setFormUrl] = useState("")

    const MAX_RETRIES = 3

    useEffect(() => {
        setFormUrl(FORM_URL)
    }, [RACE_ID])

    async function fetchRaceData(retries = 0) {
        try {
            const response = await fetch(`/api/races/${RACE_ID}`)

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }

            const { date, availableSlots } = await response.json()
            setAvailableSlots(availableSlots)
            setDate(formatDate(date))
        } catch (error) {
            if (retries < MAX_RETRIES) {
                // Exponential backoff formula: (2 ^ retries) * 1000
                // We're using a base delay of 1000ms
                const delay = Math.pow(2, retries) * 1000
                console.log(`Fetch failed, retrying in ${delay}ms...`)

                setTimeout(() => {
                    fetchRaceData(retries + 1)
                }, delay)
            } else {
                console.error("Error fetching available slots:", error)
            }
        }
    }

    // Call the function inside useEffect
    // useEffect(() => {
    //     fetchRaceData()

    //     const interval = setInterval(() => {
    //         fetchRaceData()
    //     }, 30000) // Fetch data every 30sec

    //     return () => {
    //         clearInterval(interval) // Clear the interval when the component unmounts
    //     }
    // }, [])

    const setUserDetails = ({ email, firstName = "", lastName = "" }) => {
        setFormUrl(
            FORM_URL.replace(`${FIRST_NAME}=`, `${FIRST_NAME}=${firstName}`)
                .replace(`${LAST_NAME}=`, `${LAST_NAME}=${lastName}`)
                .replace(`${EMAIL}=`, `${EMAIL}=${email}`)
        )
    }

    return (
        <>
            <MetaData
                title={`Algarve Karting Race ${date || ""}`}
                description={`Join us for the thrill of a lifetime at the Algarve Karting Race! Only ${
                    availableSlots || ""
                } slots up for grabs.`}
                location={location}
                image={kartingImage}
            />
            <Layout>
                <h1 className="text-4xl font-bold text-center my-14">
                    Algarve Karting Race
                </h1>
                <div className="md:flex gap-6 max-w-screen-2xl md:mx-auto md:mb-24">
                    <div className="md:w-1/2 flex flex-col gap-10">
                        <p className="flex items-center text-2xl ">
                            <PeopleFill className="h-6 w-6" />
                            <a
                                className="ml-3 text-2xl border-b-2 border-blue-100 text-blue-900"
                                href="#racers"
                            >
                                Racers participating
                            </a>
                        </p>
                        {/* <p className="flex items-center text-2xl">
                            <CalendarFill className="h-6 w-6" />
                            <span className="ml-3 text-2xl empty:h-8 empty:w-32 empty:bg-gray-200 empty:animate-pulse">{date !== null ? date : ""}</span>
                        </p> */}
                        <p className="flex items-center text-2xl">
                            <Location className="h-6 w-6" />
                            <a
                                className="ml-3 text-2xl border-b-2 border-blue-100 text-blue-900"
                                href="/go-karting/portimao"
                                target="_blank"
                            >
                                Portimao Karting Track
                            </a>
                        </p>
                        <div>
                            <p className="flex items-center gap-3 text-2xl mb-2">
                                <img src="/flag.svg" className="h-6 w-6" /> Want
                                in on the action?
                            </p>
                            <p>
                                Simply fill out the form and confirm your
                                participation. Your slot is guaranteed once the
                                confirmation is complete.
                            </p>
                        </div>
                        <div>
                            <p className="flex items-center gap-3 text-2xl mb-2">
                                <Timer className="h-6 w-6" />
                                Excited for a race? 
                            </p>
                            <p>
                            Begin with a 10-minute qualifier, line up, then speed off into a 20-minute race. Payments are at the track (€50.40). We're minimizing no-shows. So, strap in and let's race!
                            </p>
                        </div>
                        <div>
                            <p className="flex items-center gap-3 text-2xl mb-2">
                                <ShieldFillCheck className="h-6 w-6" />
                                Change of plans?
                            </p>
                            <p>
                                No problem at all. You can cancel up to three
                                days before the race. We've got your back -
                                we'll send reminders to your email so you won't
                                forget.
                            </p>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <ClientOnly>
                            <FirebaseProvider>
                                <LoginOrProfile
                                    setUserDetails={setUserDetails}
                                    RACE_ID={RACE_ID}
                                />
                            </FirebaseProvider>
                        </ClientOnly>
                        <hr className="my-4 border-2" />
                        <div className="flex justify-center items-center mt-4">
                            <iframe
                                src={formUrl}
                                width="600"
                                height="800"
                                className="border-0"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="p-8 mb-32 max-w-prose mx-auto bg-white rounded-xl shadow-xl text-gray-800 md:-rotate-1 my-6">
                    <p className="mb-4">
                        We're David and Gabor, two tech lovers with a passion
                        for the thrill of kart racing. Recognizing the
                        complexity of booking, payment, and cancellation
                        processes, we leveraged our tech expertise to simplify
                        them. Our goal? More time on the track, less time
                        dealing with the hassle.
                    </p>
                    <p>
                        We invite you to join us and enjoy a smoother karting
                        experience.
                    </p>
                    <p className="mt-4 font-semibold">Best,</p>
                    <p>David & Gabor</p>
                    <div className="flex -space-x-3">
                        <img
                            className="rounded-full w-10 h-10 border-2 border-white"
                            src="https://avatars.githubusercontent.com/u/22056688?v=4"
                            alt="David"
                        />
                        <img
                            className="rounded-full w-10 h-10 border-2 border-white"
                            src="https://lh3.googleusercontent.com/a-/AOh14GjrxsIx5Sii5GeEKzIJI5a7P2heiMHbPYKII_SV6EY=s96-c"
                            alt="Gabor"
                        />
                    </div>
                </div>
                <iframe
                    id="racers"
                    className="my-4 mb-12"
                    height="500px"
                    width="100%"
                    frameborder="0"
                    allowTransparency="true"
                    scrolling="auto"
                    src="https://creatorapp.zohopublic.eu/karting/karting-check-in/report-embed/Live_Participants_List/Dvb7AjYDKNEU8kqYBAAZUfq7DZy0fq9FHA2YnYpuCWPKkhgjerbUKBxN5JHVjKmxQ5EVVqZYFxMOb5AWVUJRN94fRrPC1Mvu3MJw"
                ></iframe>
            </Layout>
        </>
    )
}

const LoginOrProfile = ({ setUserDetails, RACE_ID }) => {
    const auth = useContext(FirebaseContext)
    const { user, userPublic } = auth

    useEffect(() => {
        try {
            if (
                user &&
                !user.isAnonymous &&
                userPublic?.displayName &&
                user.email
            ) {
                const email = user.email
                if (userPublic.displayName.split(" ").length === 1) {
                    setUserDetails({
                        firstName: userPublic.displayName,
                        email,
                    })
                    return
                }

                const firstName = userPublic.displayName.split(" ")[0]
                const lastName = userPublic.displayName.split(" ")[1]

                setUserDetails({
                    firstName,
                    lastName,
                    email,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [user, userPublic])

    if (user === null) return <></>

    return (
        <div className="flex flex-col items-center py-6">
            {!user || user.isAnonymous ? (
                <div className="flex items-center gap-4">
                    <p className="">
                        Speed up your registration by logging in. It's a breeze
                        to sign up for more races!
                    </p>
                    <a
                        data-testid="login"
                        href={`/profile?redirect=/races/${RACE_ID}`}
                        className="inline-btn blue-bg whitespace-nowrap"
                    >
                        Sign In
                    </a>
                </div>
            ) : (
                <>
                    {userPublic && (
                        <ProfileThumb
                            userPublic={userPublic}
                            linkToAccountAdmin
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default PortimaoRace
